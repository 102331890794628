import { isDevMode } from '@angular/core';
import { ErrorBase } from '@core/models/error.model';

// Dinh nghia cac loi client

export class AccountNotAllowLoginWithTypeError extends Error {
  public static _code = 446;

  constructor() {
    super(AccountNotAllowLoginWithTypeError._code.toString());
    isDevMode() &&
      console.error(
        `Bạn không có quyền vào cổng dành cho sinh viên (Nhan vien khong the vao trang sinh vien, va nguoc lai) khi dang nhap bang username password`
      );
  }
}

// Bạn không có quyền vào cổng dành cho sinh viên (Nhaan vien khong the vao trang sinh vien, va nguoc lai)
export class AccountTypeAndTokenNotMatchError extends Error {
  public static _code = 447;

  constructor() {
    super(AccountTypeAndTokenNotMatchError._code.toString());
    isDevMode() &&
      console.error(
        `Bạn không có quyền vào cổng dành cho sinh viên (Nhan vien khong the vao trang sinh vien, va nguoc lai) khi dang nhap bang token`
      );
  }
}

export class AccessTokenExpiredError extends Error {
  public static _code = 448;

  constructor() {
    super(AccessTokenExpiredError._code.toString());
    isDevMode() && console.error(`Token hết hạn -> Thực hiện đăng nhập lại`);
  }
}

export class InvalidAuthQueryParamsError extends Error {
  public static _code = 449;

  constructor() {
    super(InvalidAuthQueryParamsError._code.toString());
    isDevMode() &&
      console.error(`Query param để đăng nhập bằng token không hợp lệ`);
  }
}

// Loi khong xac dinh phia server hoac phia client.
export class AuthInternalServerError extends Error {
  public static _code = 450;

  constructor() {
    super(AuthInternalServerError._code.toString());
    isDevMode() &&
      console.error(`Lỗi chưa thể xác định phát sinh từ client hoặc server`);
  }
}

// Error range 45x
export class AccountInactiveError extends Error {
  public static _code = 451;

  constructor() {
    super(AccountInactiveError._code.toString());
    isDevMode() && console.error(`Tài khoản này chưa được kích hoạt`);
  }
}

export class AccountLockedError extends Error {
  public static _code = 452;

  constructor() {
    super(AccountLockedError._code.toString());
    isDevMode() && console.error(`Tài khoản này đang bị khóa`);
  }
}

export class AccountNotExistedError extends Error {
  public static _code = 453;

  constructor() {
    super(AccountNotExistedError._code.toString());
    isDevMode() && console.error(`Tài khoản này không tồn tại trong hệ thống`);
  }
}

export class WrongUsernameOrPasswordError extends Error {
  public static _code = 454;

  constructor() {
    super(WrongUsernameOrPasswordError._code.toString());
    isDevMode() && console.error(`Sai tên đăng nhập hoặc mật khẩu`);
  }
}

export class NotAllowAccountUseAppError extends Error {
  public static _code = 455;

  constructor() {
    super(NotAllowAccountUseAppError._code.toString());
    isDevMode() &&
      console.error(
        `Tài khoản không được dùng ứng dụng - cấu hình trong APP-ID`
      );
  }
}

export class AccountNotAllowUseFeatureError extends Error {
  public static _code = 456;

  constructor() {
    super(AccountNotAllowUseFeatureError._code.toString());
    isDevMode() && console.error(`Tài khoản không được dùng tính năng này`);
  }
}

export class AuthErrorModel {
  public static handle(error: ErrorBase) {
    switch (error?.desc?.options?.code) {
      case 0:
        throw new AccountInactiveError();
      // TODO: Change status at be
      // throw new NotAllowAccountUseAppError()
      case -1:
        throw new WrongUsernameOrPasswordError();
      case -2:
        throw new AccountLockedError();
      case -3:
        throw new AccountNotAllowLoginWithTypeError();
      case 404:
        throw new AccountNotExistedError();

      default:
        throw new AuthInternalServerError();
    }
  }

  public static handleErrorLoginByToken(error: ErrorBase) {
    switch (error?.desc?.options?.code) {
      case -1:
        throw new AccessTokenExpiredError();
      case -3:
      case -2:
        throw new AccountTypeAndTokenNotMatchError();
      case -403:
        throw new AccountNotAllowUseFeatureError();
    }

    throw new AuthInternalServerError();
  }

  public static handleLoginByToken3ThError(error: ErrorBase) {
    switch (error?.desc?.options?.code) {
      case -1:
        throw new AccessTokenExpiredError();
      case -3:
      case -2:
        throw new AccountTypeAndTokenNotMatchError();
      case -403:
        throw new AccountNotAllowUseFeatureError();
    }

    throw new AuthInternalServerError();
  }
}
