// ex: http://localhost:4200/?redirect=dashboard&token=eyJhbG...?acc-type=N
export const LOGIN_BY_TOKEN_ROUTE_QUERY = {
  REDIRECT: 'redirect',
  TOKEN: 'token',
  TOKEN_3TH: 'token-3th',
  ACCOUNT_TYPE: 'acc-type'
}

export const ACCOUNT_TYPE_QUERY_PARAMS = {
    // Nhaan vien
    NHAN_VIEN: 'nv',
    // Sinh vien
    SINH_VIEN: 'sv'
}

